import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBackground, setIsBackgroundImage } from "../../../Redux/vibeSlice";

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icon
import { CloudLightning, CloudRain, FireSimple, PauseCircle, PlayCircle } from "@phosphor-icons/react";

// Constants
import { ambients, vibes } from "../../../Constants";

function Vibe() {
    // Redux & useState, useRef
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const background = useSelector((state) => state.vibe.background)
    const [isPlaying, setIsPlaying] = useState([false, false, false])
    const audioRefs = useRef([])
    const dispatch = useDispatch()

    // Style
    const style = useStyle(isDarkMode);

    // Functions
    const renderIcon = (audio) => {
        switch (audio) {
            case "rain":
                return (
                    <CloudRain size={24} />
                )
            case "thunder":
                return (
                    <CloudLightning size={24} />
                )
            case "fire":
                return (
                    <FireSimple size={24} />
                )
            default:
                break;
        }
    }
    
    const clickHandler = (index) => {
        const audio = audioRefs.current[index]
        if (audio) {
            if (isPlaying[index]) {
                const newArray = [...isPlaying];
                newArray[index] = false;
                setIsPlaying(newArray)
                audio.pause()
            } else {
                const newArray = [...isPlaying];
                newArray[index] = true;
                setIsPlaying(newArray)
                audio.play()
            }
        }
    }

    const volumeHandler = (index, volume) => {
        audioRefs.current[index].volume = volume
    }

    const randomHandler = () => {
        const randomCategory = Math.floor(Math.random() * vibes.length)
        const randomBackground = Math.floor(Math.random() * vibes[randomCategory].content.length)
        if (randomCategory === 2) {
            dispatch(setIsBackgroundImage(true))
            dispatch(setBackground(vibes[randomCategory].content[randomBackground]))
        } else {
            dispatch(setIsBackgroundImage(false))
            dispatch(setBackground(vibes[randomCategory].content[randomBackground]))
        }
    }

    useEffect(() => {
        audioRefs.current = audioRefs.current.slice(0, ambients.length);
    }, []);

    return (
        <Stack
            width="100%" height="38.5%"
            direction="column" justifyContent="space-between"
            className={style.card}
        >
            <Stack>
                <Stack fontSize="24px" fontWeight="700">Background</Stack>
                <Stack
                    direction="column" gap="10px"
                    marginTop="5px"
                >
                    {vibes.map(category => {
                        return (
                            <Stack
                                direction="row" alignItems="center"
                                gap="5px"
                            >
                                {category.content.map(vibe => {
                                    return (
                                        <Stack
                                            width="30px" height="30px"
                                            borderRadius="8px"
                                            border={vibe === background ? isDarkMode ? "2px solid #fff" : "2px solid #020202" : ""}
                                            sx={{
                                                background: category.name === "pictures" ? `url(${vibe})` : `${vibe}`,
                                                backgroundSize: "cover", backgroundPosition: "center",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                if (category.name === "pictures") {
                                                    dispatch(setIsBackgroundImage(true))
                                                    dispatch(setBackground(vibe))
                                                } else {
                                                    dispatch(setIsBackgroundImage(false))
                                                    dispatch(setBackground(vibe))
                                                }
                                            }}
                                        ></Stack>
                                    )
                                })}
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>
            <Stack>
                <Stack fontSize="24px" fontWeight="700">Ambient</Stack>
                <Stack
                    direction="column" alignItems="center"
                    gap="5px"
                >
                    {ambients.map((item, index) => {
                        return (
                            <Stack
                                direction="row" alignItems="center"
                                width="100%" padding="0px 15px" gap="15px"
                            >
                                <audio 
                                    src={item.url}
                                    ref={e => (audioRefs.current[index] = e)}
                                    loop
                                />
                                {renderIcon(item.name)}
                                <Stack
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => clickHandler(index)}
                                >
                                    {isPlaying[index] ? <PauseCircle size={24} /> : <PlayCircle size={24} />}
                                </Stack>
                                <input min={0} max={1} step={0.01} type="range" className={style.range} onChange={(e) => volumeHandler(index, e.target.value)} />
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>
            <Stack
                width="100%" direction="row"
                justifyContent="center"
            >
                <Stack
                    width="75%" height="25px"
                    border="1px solid"
                    borderColor={isDarkMode ? "#fff" : "#020202"}
                    textAlign="center" borderRadius="1000px"
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={randomHandler}
                >
                    Random
                </Stack>
            </Stack>
        </Stack>
    );
}

export default Vibe;