// Pictures
import pictureOne from "../Assets/Picture-One.gif"
import pictureThree from "../Assets/Picture-Three.gif"
import pictureSix from "../Assets/Picture-Six.gif"
import pictureSeven from "../Assets/Picture-Seven.gif"
import pictureEight from "../Assets/Picture-Eight.gif"
import pictureNine from "../Assets/Picture-Nine.gif"

// Tools
export const tools = ["Tasks", "Music", "Notes", "Quotes", "Materials"]

// Quotes
export const quotes = [
    [`"There is no substitute for hard work."`, "- Thomas Edison"],
    [`"A dream doesn't become reality through magic; it takes sweat, determination, and hard work."`, "- Colin Powell"],
    [`"The only place where success comes before work is in the dictionary."`, "- Vidal Sassoon"],
    [`"Success is the result of preparation, hard work, and learning from failure."`, "- J.C. Watts"],
    [`"Opportunities are usually disguised as hard work, so most people don’t recognize them."`, "- Ann Landers"],
    [`"Striving for success without hard work is like trying to harvest where you haven’t planted."`, "- David Bly"],
    [`"Dreams don’t work unless you do."`, "- John C. Maxwell"],
    [`"It’s hard to beat a person who never gives up."`, "- Babe Ruth"],
    [`"The harder you work, the luckier you get."`, "- Henry Ford"],
    [`"I never dreamed about success. I worked for it."`, "- Estée Lauder"],
    [`"Perseverance is the hard work you do after you get tired of doing the hard work you already did."`, "- Newt Gingrich"],
    [`"Great things come from hard work and perseverance. No excuses."`, "- Kobe Bryant"],
    [`"You can’t have a million-dollar dream with a minimum-wage work ethic."`, "- Stephen C. Hogan"],
    [`"Success usually comes to those who are too busy to be looking for it."`, "- Henry David Thoreau"],
    [`"Things may come to those who wait, but only the things left by those who hustle."`, "- Abraham Lincoln"],
    [`"I’m a firm believer in luck, and I find the harder I work, the luckier I get."`, "- Jim Tressel"],
    [`"Success isn’t always about greatness. It’s about consistency. Consistent hard work leads to success. Greatness will come."`, "- Dwayne Johnson"],
    [`"The more I want to get something done, the less I call it work."`, "- Richard Bach"],
    [`"Plans are only good intentions unless they immediately degenerate into hard work."`, "- Peter Drucker"],
    [`"If people knew how hard I had to work to gain my mastery, it would not seem so wonderful at all."`, "- Michelangelo"],
    [`"Inspiration is the windfall from hard work and focus. Muses are too unreliable to keep on the payroll."`, "- Helen Hanson"],
    [`"Nothing in life is worth having if it comes too easy."`, "- Theodore Roosevelt"],
    [`"The harder the conflict, the more glorious the triumph."`, "- Thomas Paine"],
    [`"Do the best you can until you know better. Then when you know better, do better."`, "- Maya Angelou"],
    [`"The man who moves a mountain begins by carrying away small stones."`, "- Confucius"],
    [`"You’re never a loser until you quit trying."`, "- Mike Ditka"],
    [`"Success is not final, failure is not fatal: It is the courage to continue that counts."`, "- Winston Churchill"],
    [`"The future depends on what you do today."`, "- Mahatma Gandhi"],
    [`"Action is the foundational key to all success."`, "- Pablo Picasso"],
    [`"Nobody ever drowned in his own sweat."`, "- Ann Landers"],
    [`"The only limit to our realization of tomorrow will be our doubts of today."`, "- Franklin D. Roosevelt"],
    [`"Anything in life worth having is worth working for."`, "- Andrew Carnegie"],
    [`"Genius is 1% talent and 99% hard work."`, "- Albert Einstein"],
    [`"Someday you will think it was all worth it."`, "- Jasmin Kouhi"],
]

// Vibe
export const vibes = [
    {
        name: "colors",
        content: ["#3d9970", "#39cccc", "#ff851b", "#d81b60", "#605ca8"],
    },
    {
        name: "gradients",
        content: [
            "linear-gradient(90deg, hsla(153, 43%, 42%, 1) 0%, hsla(180, 59%, 51%, 1) 100%)",
            "linear-gradient(90deg, hsla(180, 59%, 51%, 1) 0%, hsla(243, 30%, 51%, 1) 100%)",
            "linear-gradient(90deg, hsla(28, 100%, 55%, 1) 0%, hsla(338, 78%, 48%, 1) 100%)",
            "linear-gradient(90deg, hsla(28, 100%, 55%, 1) 0%, hsla(243, 30%, 51%, 1) 100%)",
            "linear-gradient(90deg, hsla(338, 78%, 48%, 1) 0%, hsla(243, 30%, 51%, 1) 100%)",
        ],
    },
    {
        name: "pictures",
        content: [
            pictureOne,
            pictureThree,
            pictureSix,
            pictureSeven,
            pictureEight,
            pictureNine,
        ],
    }
]

export const ambients = [
    {
        name: "rain",
        url: "https://www.dropbox.com/scl/fi/c2pertz6tndk1769c1hko/Rain.mp3?rlkey=f9qec7ppzk879vdr72xmwdvuk&dl=1",
    },
    {
        name: "fire",
        url: "https://www.dropbox.com/scl/fi/bjh9fvmz9np703r1lfjjd/Fire.mp3?rlkey=niyyw2euvmiav5vi88qqvrk2w&dl=1",
    },
]

// Feedback
export const numbers = [1, 2, 3, 4, 5]