import { useSelector } from "react-redux";
import { useContext, useState } from "react";

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { FloppyDisk, XCircle } from "@phosphor-icons/react";

// Context
import { timerContext } from "./Timer";

function SettingSide() {
    // Redix & useStates
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const [tempWorkDuration, setTempWorkDuration] = useState()
    const [tempBreakDuration, setTempBreakDuration] = useState()
    const [error, setError] = useState({
        workInput: false,
        breakInput: false,
    })

    // Style
    const style = useStyle(isDarkMode);

    // Context
    const {
        setWorkDuration,
        setBreakDuration,
        setIsActive,
        setSide,
    } = useContext(timerContext)

    // Functions
    const clickHandler = (type) => {
        if (type === "save") {
            setWorkDuration(tempWorkDuration * 60)
            setBreakDuration(tempBreakDuration * 60)
        }

        setIsActive(false)
        setSide("timer")
    }

    return (
        <>
            <Stack
                direction="row" alignItems="center"
                gap="15px"
            >
                <Stack
                    direction="column" gap="5px"
                >
                    <Stack
                        fontSize="16px" fontWeight="600"
                    >
                        focus duration
                    </Stack>
                    <Stack
                        sx={{
                            padding: "5px", border: "2px solid",
                            borderRadius: "10px", borderColor: isDarkMode ? error.workInput ? "red" : "#fff" : error.workInput ? "red" : "#020202",
                        }}
                    >
                        <input
                            className={style.input}
                            type="number" 
                            value={tempWorkDuration} 
                            onChange={(element) => {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    workInput: false
                                }))
                                setTempWorkDuration(element.target.value)
                            }}
                        />
                    </Stack>
                    <Stack fontSize="12px">Minutes</Stack>
                </Stack>
                <Stack
                    direction="column" gap="5px"
                >
                    <Stack
                        fontSize="16px" fontWeight="600"
                    >
                        break duration
                    </Stack>
                    <Stack
                        sx={{
                            padding: "5px", border: "2px solid",
                            borderRadius: "10px", borderColor: isDarkMode ? error.workInput ? "red" : "#fff" : error.workInput ? "red" : "#020202",
                        }}
                    >
                        <input
                            className={style.input}
                            type="number" 
                            value={tempBreakDuration} 
                            onChange={(element) => {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    breakInput: false
                                }))
                                setTempBreakDuration(element.target.value)
                            }} 
                        />
                    </Stack>
                    <Stack fontSize="12px">Minutes</Stack>
                </Stack>
                <Stack
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        if (tempWorkDuration && tempBreakDuration) {
                            clickHandler("save")
                        } else {
                            if (!tempWorkDuration) {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    workInput: true
                                }))
                            } if (!tempBreakDuration) {
                                setError((prevItem) => ({
                                    ...prevItem,
                                    breakInput: true
                                }))
                            }
                        }
                    }}
                >
                    <FloppyDisk size={32} />
                </Stack>
                <Stack
                    position="absolute" top="10px" right="10px"
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={() => clickHandler("back")}
                >
                    <XCircle size={24} />
                </Stack>
            </Stack>
        </>
    );
}

export default SettingSide;