import { useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setBackground, setIsBackgroundImage, setIsDarkMode } from './Redux/vibeSlice';

// MUI
import { Stack } from "@mui/material";

// Component
import Dashboard from './Components/Dashboard/Dashboard';
// import SignUp from './Components/SignUp/SignUp';
// import SignIn from './Components/SignIn/SignIn';

// Constants
import { PATH } from "./Constants/path";

function App() {
  // Redux & useRef
  const isDarkMode = useSelector((state) => state.vibe.isDarkMode);
  const isBackgroundImage = useSelector((state) => state.vibe.isBackgroundImage);
  const background = useSelector((state) => state.vibe.background);
  const dispatch = useDispatch();
  const elementRef = useRef(null);

  // Localstorage
  useEffect(() => {
    const previousIsDarkMode = JSON.parse(localStorage.getItem("IS_DARK_MODE"));
    const previousIsBackgroundImage = JSON.parse(localStorage.getItem("IS_BACKGROUND_IMAGE"));
    const previousBackground = localStorage.getItem("BACKGROUND");
    if (previousIsDarkMode) {
      dispatch(setIsDarkMode(previousIsDarkMode))
    } if (previousIsBackgroundImage) {
      dispatch(setIsBackgroundImage(previousIsBackgroundImage))
    } if (previousBackground) {
      dispatch(setBackground(previousBackground))
    }
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem("IS_DARK_MODE", JSON.stringify(isDarkMode))
  }, [isDarkMode])

  useEffect(() => {
    localStorage.setItem("IS_BACKGROUND_IMAGE", JSON.stringify(isBackgroundImage))
  }, [isBackgroundImage])

  useEffect(() => {
    localStorage.setItem("BACKGROUND", background)
  }, [background])

  return (
    <Stack
      width="100vw" height="100vh"
      color={isDarkMode ? "#fff" : "#020202"}
      sx={
        isBackgroundImage ?
          {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover', backgroundPosition: "center"
          }
        :
          {
            background: background,
          }
      }
      ref={elementRef}
    >
      <BrowserRouter>
        <Routes>
          {/*<Route path={PATH.SIGN_UP} element={<SignUp />} />
          <Route path={PATH.SIGN_IN} element={<SignIn />} />*/}
          <Route path={PATH.DASHBOARD} element={<Dashboard elementRef={elementRef} />} />
          <Route path="*" element={<Navigate to={PATH.DASHBOARD} />} />
        </Routes>
      </BrowserRouter>
    </Stack>
  );
}

export default App;
