import { useSelector } from "react-redux";

// MUI
import { Stack } from "@mui/material";

// Components
import Timer from "./Timer/Timer";
import Tools from "./Tools/Tools";
import Vibe from "./Vibe/Vibe";
import Tasks from "./Tools/Tasks";
import Notes from "./Tools/Notes";
import Quotes from "./Tools/Quotes";
import Control from "./Control/Control";
import Feedback from "./Feedback/Feedback";
import Performance from "./Control/Performance";

// Functions
import { isMobileDevice } from "../../Constants/functions";

function Dashboard({ elementRef }) {
    // Redux
    const notes = useSelector((state) => state.tools.notes)

    if (!isMobileDevice()) {
        return (
            <Stack
                width="100%" height="100%" padding="15px"
                direction="row" justifyContent="space-between"
            >
                <Stack
                    width="350px" height="100%"
                    direction="column" justifyContent="space-between"
                >
                    <Timer />
                    <Tools />
                    <Vibe />
                </Stack>
                <Tasks />
                {notes.map((note) => {
                    return (
                        <Notes key={note} id={note} />
                    )
                })}
                <Quotes />
                <Control elementRef={elementRef} />
                <Performance />
                <Feedback />
                <Stack
                    padding="5px 10px" height="fit-content"
                    fontWeight="700" fontSize="20px"
                    borderRadius="10px"
                    sx={{
                        background: "#f00"
                    }}
                >BETA</Stack>
            </Stack>
        );
    } else {
        return (
            <Stack
                width="100%" height="100%" direction="row" 
                justifyContent="center" alignItems="center"
                padding="15px"
            >
                <Stack>For the best viewing experience, please switch to a larger screen or resize your window. Thank you for understanding!</Stack>
            </Stack>
        );
    }
}

export default Dashboard;